require("vendor/slick/slick.min");
require("vendor/wow/wow.min");
//require("vendor/animsition/animsition.min");
require("vendor/circle-progress/circle-progress.min");
//require("vendor/perfect-scrollbar/perfect-scrollbar.min");
require("vendor/chartjs/Chart.bundle.min");
require("vendor/select2/select2.min");
require("vendor/smk-accordion/accordion.min");
require("vendor/sweetalert/sweetalert.min");
require("vendor/bootstrap-datetimepicker/bootstrap-datetimepicker");

// vector-map, lightbox2, coountdown
// are not being required. if used pls require any of them; libs on this folder;
